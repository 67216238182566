import { formatNewLine, TranslatedComponent, translation } from '@hh.ru/front-static-app';

import styles from './styles.less';

const TrlKeys = {
    title: 'index.employer.title',
};

const Header: TranslatedComponent = ({ trls }) => {
    return (
        <h1 data-qa="employer-index-title" className={styles.header}>
            {formatNewLine(trls[TrlKeys.title])}
        </h1>
    );
};

export default translation(Header);
