// tempexp_36944_file
import { FC, PropsWithChildren } from 'react';
import classNames from 'classnames';

import styles from './styles.less';

interface CenteredProps {
    vertical?: boolean;
}

const Centered: FC<PropsWithChildren & CenteredProps> = ({ children, vertical }) => {
    return (
        <div
            className={classNames(styles.centered, {
                [styles.vertical]: vertical,
            })}
        >
            <div>{children}</div>
        </div>
    );
};

export default Centered;
