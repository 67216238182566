// tempexp_36944_file
import { FC } from 'react';

import { VSpacing } from '@hh.ru/magritte-ui';

import Centered from 'src/pages/AnonymousEmployer/magritte/Centered/Centered';

import Description from 'src/pages/AnonymousEmployer/magritte/PublishVacancy/Description/Description';
import Header from 'src/pages/AnonymousEmployer/magritte/PublishVacancy/Header/Header';
import PublishVacancyButton from 'src/pages/AnonymousEmployer/magritte/PublishVacancy/PublishVacancyButton';

import styles from './styles.less';

const PublishVacancy: FC = () => {
    return (
        <Centered vertical>
            <div className={styles.publishVacancy}>
                <Header />
                <VSpacing default={16} />
                <Description />
                <VSpacing default={32} />
                <PublishVacancyButton />
            </div>
        </Centered>
    );
};

export default PublishVacancy;
