// tempexp_36944_file
import classNames from 'classnames';

import { Button } from '@hh.ru/magritte-ui';

import { useIsZarplataPlatform } from 'src/hooks/usePlatform';

import styles from './styles.less';

const LandingButton: typeof Button = (props) => {
    const isZp = useIsZarplataPlatform();

    return (
        <div
            className={classNames({
                [styles.zpButton]: isZp,
            })}
        >
            <Button {...props} style={isZp ? 'contrast' : 'accent'} />
        </div>
    );
};

export default LandingButton;
