// tempexp_36944_file
import { FC } from 'react';

import { Title, VSpacing } from '@hh.ru/magritte-ui';

import Centered from 'src/pages/AnonymousEmployer/magritte/Centered/Centered';

const ResumeSearch: FC = () => {
    return (
        <Centered>
            <VSpacing default={80} />
            <Title
                Element="h2"
                size="medium"
                alignment="center"
                description={
                    'Не ждите откликов – найдите идеального сотрудника сами среди 8 577 469 резюме у 6 361 878 соискателей'
                }
            >
                Какие сотрудники есть на hh.ru?
            </Title>
            <VSpacing default={80} />
        </Centered>
    );
};

export default ResumeSearch;
