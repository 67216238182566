// tempexp_36944_file
import { FC } from 'react';

import { useIsZarplataPlatform } from 'src/hooks/usePlatform';
import { useIsHhru } from 'src/hooks/useSites';

import Benefits from 'src/pages/AnonymousEmployer/components/Benefits';
import Candidates from 'src/pages/AnonymousEmployer/components/Candidates';
import SearchSteps from 'src/pages/AnonymousEmployer/components/SearchSteps';
import Steps from 'src/pages/AnonymousEmployer/components/SearchSteps/Steps';
import Support from 'src/pages/AnonymousEmployer/components/Support';
import CardSection from 'src/pages/AnonymousEmployer/magritte/CardSection/CardSection';
import HeroSection from 'src/pages/AnonymousEmployer/magritte/HeroSection/HeroSection';
import PublishVacancy from 'src/pages/AnonymousEmployer/magritte/PublishVacancy/PublishVacancy';
import ResumeSearch from 'src/pages/AnonymousEmployer/magritte/ResumeSearch';

const MagritteAnonymousEmployer: FC = () => {
    const shouldShowPrice = useIsHhru();
    const isZp = useIsZarplataPlatform();

    if (isZp) {
        // There will be more differences later
        return (
            <>
                <HeroSection isWide>
                    <PublishVacancy />
                </HeroSection>
                <CardSection style="neutral">
                    <ResumeSearch />
                    <Steps />
                </CardSection>
                <SearchSteps isRegularHighlight={shouldShowPrice} />
                <Benefits isRegularHighlight={!shouldShowPrice} />
                <Candidates isRegularHighlight={shouldShowPrice} />
                <Support />
            </>
        );
    }

    return (
        <>
            <HeroSection>
                <PublishVacancy />
            </HeroSection>
            <CardSection style="neutral">
                <ResumeSearch />
            </CardSection>
            <SearchSteps isRegularHighlight={shouldShowPrice} />
            <Benefits isRegularHighlight={!shouldShowPrice} />
            <Candidates isRegularHighlight={shouldShowPrice} />
            <Support />
        </>
    );
};

export default MagritteAnonymousEmployer;
