import { useRef, useState } from 'react';

import Column, { ColumnsWrapper } from 'bloko/blocks/column';
import Info from 'bloko/blocks/drop/Info';
import BlokoLink, { LinkAppearance } from 'bloko/blocks/link';
import Text, { TextSize } from 'bloko/blocks/text';
import VSpacing from 'bloko/blocks/vSpacing';
import useBreakpoint, { Breakpoint } from 'bloko/common/hooks/useBreakpoint';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { formatToReactComponent, format } from 'bloko/common/trl';

import translation from 'src/components/translation';
import { useIsZarplataPlatform } from 'src/hooks/usePlatform';
import { useSelector } from 'src/hooks/useSelector';
import { useIsRabotaBy } from 'src/hooks/useSites';
import IndexSection, { HighlightType } from 'src/pages/AnonymousEmployer/components/Section';
import Subtitle from 'src/pages/AnonymousEmployer/components/Subtitle';
import Title from 'src/pages/AnonymousEmployer/components/Title';

import styles from './candidates.less';

interface CandidatesProps {
    isRegularHighlight?: boolean;
}

enum CandidateParagraph {
    Trust = 'trust',
    Here = 'here',
    Quality = 'quality',
    Interested = 'interested',
}

const TrlKeys = {
    title: 'index.employer.candidates.title',
    [CandidateParagraph.Trust]: {
        title: 'index.employer.candidates.trust.title',
        description: 'index.employer.candidates.trust.description',
        place: 'index.employer.candidates.trust.place',
        tip: 'index.employer.candidates.trust.tip',
    },
    [CandidateParagraph.Here]: {
        title: 'index.employer.candidates.here.title',
        description: 'index.employer.candidates.here.description',
        place: 'index.employer.candidates.here.place',
        tip: 'index.employer.candidates.here.tip',
    },
    [CandidateParagraph.Quality]: {
        title: 'index.employer.candidates.quality.title',
        description: 'index.employer.candidates.quality.description',
    },
    [CandidateParagraph.Interested]: {
        title: 'index.employer.candidates.interested.title',
        description: 'index.employer.candidates.interested.description',
        place: 'index.employer.candidates.interested.place',
        tip: 'index.employer.candidates.interested.tip',
    },
};

const Candidates: TranslatedComponent<CandidatesProps> = ({ trls, isRegularHighlight }) => {
    const [tipType, setTipType] = useState<CandidateParagraph | null>(null);

    const resumeCount = useSelector((state) => state.globalStatistics?.resumes) ?? 0;
    const isXs = useBreakpoint(Breakpoint.M) === Breakpoint.XS;
    const isZP = useIsZarplataPlatform();
    const isRabotaBy = useIsRabotaBy();

    const resumeCountInMillions = resumeCount / 1.0e6;
    const formattedResumeCount = parseFloat(resumeCountInMillions.toFixed(1));
    const activatorRef = useRef(null);
    const herePlaceActivatorRef = useRef(null);
    const interestedPlaceActivatorRef = useRef(null);

    return (
        <IndexSection highlight={isRegularHighlight ? HighlightType.Regular : HighlightType.Light}>
            <ColumnsWrapper>
                <Column xs="4" s="8" m="6" l="8">
                    <div className={styles.img} />
                </Column>
                <Column xs="4" s="8" m="6" l="8">
                    <Title>{trls[TrlKeys.title]}</Title>
                    <VSpacing base={8} />
                    <Subtitle>{trls[TrlKeys.trust.title]}</Subtitle>
                    <VSpacing base={3} />
                    {isZP ? (
                        <Text size={isXs ? TextSize.Medium : TextSize.Large}>{trls[TrlKeys.trust.description]}</Text>
                    ) : (
                        <Text size={isXs ? TextSize.Medium : TextSize.Large}>
                            {formatToReactComponent(trls[TrlKeys.trust.description], {
                                '{0}': (
                                    <Info
                                        show={tipType === CandidateParagraph.Trust}
                                        render={() => trls[TrlKeys.trust.tip]}
                                        onClose={() => setTipType(null)}
                                        activatorRef={activatorRef}
                                    >
                                        <BlokoLink
                                            onClick={() => setTipType(CandidateParagraph.Trust)}
                                            data-qa={`recommended-dbaccess-tip-link`}
                                            appearance={LinkAppearance.Pseudo}
                                            ref={activatorRef}
                                        >
                                            {trls[TrlKeys.trust.place]}
                                        </BlokoLink>
                                    </Info>
                                ),
                            })}
                        </Text>
                    )}
                    <VSpacing base={7} />
                    <Subtitle>{trls[TrlKeys.here.title]}</Subtitle>
                    <VSpacing base={3} />
                    <Text size={isXs ? TextSize.Medium : TextSize.Large}>
                        {isRabotaBy
                            ? formatToReactComponent(trls[TrlKeys.here.description], {
                                  '{0}': formattedResumeCount,
                                  '{1}': (
                                      <Info
                                          show={tipType === CandidateParagraph.Here}
                                          render={() => trls[TrlKeys.here.tip]}
                                          onClose={() => setTipType(null)}
                                          activatorRef={herePlaceActivatorRef}
                                      >
                                          <BlokoLink
                                              onClick={() => setTipType(CandidateParagraph.Here)}
                                              appearance={LinkAppearance.Pseudo}
                                              ref={herePlaceActivatorRef}
                                          >
                                              {trls[TrlKeys.here.place]}
                                          </BlokoLink>
                                      </Info>
                                  ),
                              })
                            : format(trls[TrlKeys.here.description], {
                                  '{0}': formattedResumeCount,
                              })}
                    </Text>
                    <VSpacing base={7} />
                    {!isRabotaBy && (
                        <>
                            <Subtitle>{trls[TrlKeys.quality.title]}</Subtitle>
                            <VSpacing base={3} />
                            <Text size={isXs ? TextSize.Medium : TextSize.Large}>
                                {trls[TrlKeys.quality.description]}
                            </Text>
                            <VSpacing base={7} />
                        </>
                    )}
                    <Subtitle>{trls[TrlKeys.interested.title]}</Subtitle>
                    <VSpacing base={3} />
                    <Text size={isXs ? TextSize.Medium : TextSize.Large}>
                        {isRabotaBy
                            ? formatToReactComponent(trls[TrlKeys.interested.description], {
                                  '{0}': (
                                      <Info
                                          show={tipType === CandidateParagraph.Interested}
                                          render={() => trls[TrlKeys.interested.tip]}
                                          onClose={() => setTipType(null)}
                                          activatorRef={interestedPlaceActivatorRef}
                                      >
                                          <BlokoLink
                                              onClick={() => setTipType(CandidateParagraph.Interested)}
                                              appearance={LinkAppearance.Pseudo}
                                              ref={interestedPlaceActivatorRef}
                                          >
                                              {trls[TrlKeys.interested.place]}
                                          </BlokoLink>
                                      </Info>
                                  ),
                              })
                            : trls[TrlKeys.interested.description]}
                    </Text>
                </Column>
            </ColumnsWrapper>
        </IndexSection>
    );
};

export default translation(Candidates);
